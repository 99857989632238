<template>
    <div
        ref="chat"
        class="chat"
    >
        <div
            ref="chat_wrapper"
            class="chat-wrapper"
        >
            <radius-loader v-if="listLoading && !innerMessages.length" />
            <template v-else>
                <message
                    v-for="(message, message_index) in innerMessages"
                    :key="message_index"
                    :message="message"
                />
            </template>
        </div>
        <send-message-form
            :last-message="lastMessage"
            :is-loading="isLoading"
            :status="ticket.status_support"
            :ticket-id="ticket.ticketid"
            @typing="typingListener"
            @sendMessage="sendMessage"
        >
            <div
                v-if="typingMessage"
                class="typing-wrapper"
            >
                {{ typingMessage }}
                <div class="dot-flashing" />
            </div>
        </send-message-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

// Components
import Message from '@/components/common/chat/Message.vue'
import SendMessageForm from '@/components/common/chat/SendMessageForm.vue'
import RadiusLoader from '@/components/common/RadiusLoader'

import {
    sendMessage
} from '@/services/support'

export default {
    components: {
        Message,
        SendMessageForm,
        RadiusLoader
    },
    props: {
        ticket: {
            type: Object,
            required: true
        },
        messages: {
            type: Array
        }
    },
    data() {
        return {
            isLoading: false,
            flag: true,
            innerMessages: [],
            typing: false,
            typingMessage: '',
            listLoading: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainClientInfo'
        ]),
        lastMessage() {
            return this.innerMessages[this.innerMessages.length - 1]
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticket.ticketid
            })
        },
        messages() {
            this.$nextTick(() => {
                console.log(this.messages);
                this.scrollMessages()
            })
        }
    },
    async created() {
        this.markAsRead()
        try {
            this.chatWSConnect()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error');
        }
        this.innerMessages = [...this.messages]
        this.$nextTick(() => {
            this.scrollMessages()
        })
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', { ticketid: this.ticket.ticketid })
    },
    methods: {
        scrollMessages() {
            window.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        typingListener(value) {
            this.typing = value
        },
        async sendMessage({ message }) {
            try {
                this.isLoading = true;
                const data = await sendMessage({
                    ticketid: this.ticket.ticketid,
                    message,
                    hash: this.$route.query.hash
                }, this.getterIsAuth)
                eventBus.$emit('showSnackBar', 'Your message has been received', 'success');
                this.innerMessages = [...this.innerMessages, data]
                this.$nextTick(() => { this.scrollMessages() })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false;
            }
        },
        markAsRead() {
            this.$emit('markAsRead')
        },
        chatWSConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticket.ticketid, hash: this.$route.query.hash })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainClientInfo.id) return
                this.innerMessages = [...this.innerMessages, data]
                this.$nextTick(() => {
                    this.scrollMessages()
                })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Client' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
            this.$socket.on('disconnect', () => {
                this.chatWSConnect()
            });
        }
    }
}
</script>

<style lang="scss">
    .chat-wrapper{
        display: flex;
        flex-direction: column;
    }
    .chat{
        padding-bottom: 30px;
    }
</style>
