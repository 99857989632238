<template>
    <div class="ticket-card-header">
        <div
            class="ticket-card-header__left-side"
            :class="{'ticket-card-header__left-side-details' : $route.name.includes('ticket_')}"
        >
            <div class="ticket-card-header__left-side-date-wrapper">
                <span
                    v-if="mode === 'list'"
                    class="ticket-card-header__left-side-index"
                >
                    {{ index + 1 }}
                </span>
                <!-- start: dates -->
                <span class="ticket-card-header__left-side-date">
                    <span style="font-weight: 600">
                        {{ ticket.created_at | moment_from }} ago
                    </span>
                </span>
            </div>
            <!-- end: dates -->
            <!-- start: status -->
            <span
                class="ticket-card-header__left-side-status card_title__status"
                :class="getStatusClassName"
            >
                {{ statusOpen }}
            </span>
            <!-- end: status -->
        </div>
        <div class="ticket-card-header__right-side">
            <div class="ticket-card-header__top">
                <!-- start: title -->
                <router-link
                    :is="mode === 'list' ? 'router-link' : 'span'"
                    v-if="ticket.ticketid"
                    :to="mode === 'list' && {
                        name: 'ticket_details',
                        params: {
                            id: ticket.ticketid
                        }
                    }"
                    class="ticket-card-header__top-title"
                >
                    <div class="ticket-card-header__top-topic">
                        <span class="ticket-card-header__top__subtopic-type">
                            <span v-if="longNamesType">{{ longNamesType }}</span>
                            <span v-if="longNamesCategory"> {{ longNamesCategory }}</span>
                            <span v-if="longNamesResolution"> {{ longNamesResolution }}</span>
                        </span>
                        <span class="ticket-card-header__top-subtopic ticket-card-header__top-subtopic--title">
                            «{{ titleFragment }}»
                            <span
                                class="custom-tooltip"
                                :class="{'ticket-card-header__top-custom-tooltip-none' : !isShowToolTip}"
                            >
                                <p>!</p>
                                <span>
                                    {{ ticket.subject }}
                                </span>
                            </span>
                        </span>
                    </div>
                </router-link>
                <!-- end: title -->
                <!-- start: stats -->
                <div class="ticket-card-header__top-stats">
                    <!-- start: notification count -->
                    <div
                        class="ticket-card-header__top-bell-wrapper "
                        :class="getBubles === 0 ? 'grayscale' : ''"
                    >
                        <span class="ticket-card-header__top-bell">
                            <Bell />
                            <span
                                v-if="getBubles > 0"
                                class="bubble--unread bubble--unread-header"
                            >
                                {{ getBubles }}
                                <span class="tool">
                                    <div v-if="unreadMessages > 0">{{ unreadMessages }} new messages</div>
                                </span>
                            </span>
                        </span>
                    </div>
                    <!-- end: notification count -->
                </div>
                <!-- end: stats -->
            </div>
            <div class="ticket-card-header__bottom">
                <message
                    :message="ticket.last_message"
                />
                <div class="ticket-card-header__bottom-btn">
                    <router-link
                        v-if="!$route.name.includes('ticket_')"
                        :to="{
                            name: 'ticket_details',
                            params: { id: ticket.ticketid }
                        }"
                        class="ticket-card-header__bottom-btn-details"
                    >
                        View details
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import filtersMixin from '@/mixins/filters-mixin.js'
import Bell from '@/components/icons/Bell'
import Message from '@/components/common/chat/Message.vue'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    name: 'SupportCardHeaderNew',
    components: {
        Message,
        Bell
    },
    mixins: [filtersMixin],
    props: {
        mode: {
            type: String
        },
        ticket: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 1
        },
        additionalClass: {
            type: String
        },
        unreadMessages: {
            type: Number,
            required: false
        },
        longNamesCategory: {
            type: String
        },
        longNamesResolution: {
            type: String
        },
        longNamesType: {
            type: String
        },
        isCardOpen: {
            type: Boolean
        }
    },
    data() {
        return {
            isShowToolTip: false,
            themecolor: EnvSettings.styleSetting.color,
            statusOpen: ''
        }
    },
    computed: {
        getStatusClassName() {
            if (this.statusOpen === 'OPEN') return 'card_title__status--success'
            return 'card_title__status--error'
        },
        getBubles() {
            return this.unreadMessages
        },
        titleFragment() {
            const ellipsis = '...'
            if (this.ticket.subject.slice(0, 60).length < this.ticket.subject.length) {
                return this.ticket.subject.slice(0, 60) + ellipsis
            }
            return this.ticket.subject.slice(0, 60)
        }
    },
    mounted() {
        eventBus.$on('ChangeStatusOpen', (ticketIdClosed) => {
            if (this.ticketId === ticketIdClosed) {
                this.statusOpen = 'OPEN'
            }
        })
        eventBus.$on('ChangeStatusClosed', (ticketIdClosed) => {
            if (this.ticketId === ticketIdClosed) {
                this.statusOpen = 'CLOSED'
            }
        })
    },
    created() {
        this.statusOpen = this.ticket.status_support
        this.ticket.subject.length > 60 ? this.isShowToolTip = true : this.isShowToolTip = false
    },
    beforeDestroy() {
        eventBus.$off('ChangeStatusOpen')
        eventBus.$off('ChangeStatusClosed')
    }
}
</script>

<style lang="scss">

.ticket-card-header{
    display: flex;
    font-size: 14px;
    @media all and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
    }
    &__left-side {
        width: 270px;
        padding: 0 20px 0 40px;
        @media all and (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0;
        }
        @media all and (max-width: 400px) {
            flex-direction: column;
        }
    }
    &__left-side-details{
        padding: 0 20px 0 10px;

    }
    &__left-side-date-wrapper{
        display: contents;
        @media all and (max-width: 400px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }
    &__left-side-index{
        position: absolute;
        left: 15px;
        width: 25px;
        color: #fff;
        font-weight: 700;
        @include media768max {
            position: inherit;
            padding: 20px 23px;
            background: #1F2939;
            width: auto;
            margin-right: 20px;
            border-radius: 4px;
        }
    }
    &__left-side-date{
        margin-bottom: 12px;
        display: block;
        .date {
            &--success {
                display: flex;
                margin-top: 10px;
                color: #59c74d;
            }
            &--error {
                color: $error-color;
            }
            p {
                margin-top: 10px;
                display: flex;
            }
        }
        @include media768max {
            margin-bottom: 0;
            width: 100%;
        }
    }
    &__left-side-status{
        grid-area: status;
        text-align: center;
        justify-self: center;
        margin: 15px 0;
        width: 100%;
        font-weight: 600;
        @include media768max {
            justify-self: initial;
        }
    }
    &__right-side{
        width: 80%;
        border-left: 1px solid #EAEAEA;
        padding-left: 15px;
        @media all and (max-width: 768px) {
            width: 100%;
            padding-left: 0;
            border-left: none;
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media all and (max-width: 768px) {
            padding: 15px 0;
        }
        // @media all and (max-width: 576px) {
        //     flex-direction: column;
        //     align-items: flex-start;
        //     border-top: 1px solid #e7ecf4;
        //     padding: 20px 0 0 0;
        //     margin-top: 10px;
        // }
    }
    &__top-title{
        margin-bottom: 15px;
        color: #000;
        @include media576max {
            margin-bottom: 0;
        }
    }
    &__top-topic{
        grid-area: title;
        display: flex;
        flex-direction: column;
        @include media576max {
            flex-wrap: wrap;
        }
    }
    &__top-custom-tooltip-none {
        display: none;
    }
    &__top__subtopic-type{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 5px;
        margin-bottom: 10px;
        span {
            margin-right:25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: max-content;
            min-height: 32px;
            padding: 0 12px;
            border-radius: 5px;
            font-weight: 700;
            background-color: rgba($main-color, 0.1);
            border-color: rgba($main-color, 0.1);
            color: rgb(40, 64, 103);
            caret-color: rgb(40, 64, 103);
            position: relative;
            &::after {
                content: ">";
                margin-right: 5px;
                position: absolute;
                right: -23px;
            }
            &:last-child{
                &::after {
                    display: none;
                }
            }
        }
        @include media576max {
            font-size: 12px;
            min-height: 26px;
        }
    }
    &__top-subtopic{
        position: relative;
        margin-bottom: 10px;
        &--title{
            margin-bottom: 0;
            @media all and (max-width: 576px) {
                margin-bottom: 20px;
            }
        }
        @media all and (max-width: 576px) {
            margin-right: 20px;
        }
        .custom-tooltip {
            width: 17px;
            height: 17px;
            font-size: 12px;
        }
    }
    &__top-stats{
        display: flex;
        align-items: center;
        margin: 0px 0px 20px 20px;
        // @media all and (max-width: 576px) {
        //     width: 100%;
        //     display: flex;
        //     justify-content: space-between;
        //     margin-left: 0;
        // }
    }
    &__top-stats-discount{
        color: #219653;
        background: rgba(89, 199, 77, 0.1);
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding: 6px 12px;
        border-radius: 50px;
    }
    &__top-stats-discount-text{
        display: flex;
        align-items: center;
    }
    &__top-stats-discount-icon{
        background-image: url('~@/assets/img/svg/discount-code.svg');
        background-size: cover;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 6px;
    }
    &__top-stats-total{
        font-weight: bold;
        color: #1F2939;
        font-size: 20px;
        line-height: 1;
        padding: 0 10px;
    }
    &__top-stats-bids {
        font-weight: 700;
        &-counter {
            width: 25px;
            height: 25px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba($color: #1FB119, $alpha: 1.0);
            color: #fff;
            border-radius: 100px;
        }
    }
    &__top-bell-wrapper{
        justify-self: center;
        margin: 0 25px 0 15px;
    }
    &__top-bell{
        display: inline-flex;
        position: relative;
    }
    &__top-bell .bubble--unread-header{
        top: -11px;
        right: -15px;
    }
    &__top-bell .bubble--unread{
        width: 18px;
        height: 18px;
        font-size: 10px;
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        //margin-right: 35px;
        @media all and (max-width: 768px) {
            width: 100%;
        }
        @media all and (max-width: 576px) {
            flex-wrap: wrap;
        }
        .message {
            margin: 0 10px;
            flex: 1 0 calc(100% - 150px);
            max-width: initial;
        }
    }
    &__bottom-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        // width: 100%;
        @media all and (max-width: 576px) {
            width: 100%;
            margin-left: 0;
            flex-wrap: wrap;
        }
    }
    &__bottom-btn-wrapper{
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    &__bottom-btn .btn-base {
        height: 36px;
        padding: 6px 12px;
        text-transform: inherit;
        border-radius: 4px;
        margin-right: 12px;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    &__bottom-btn-text{
        margin-left: 10px;
        white-space: nowrap;
        @media all and (max-width: 768px) {
            display: none;
        }
        @media all and (max-width: 576px) {
            display: block;
        }
    }
    &__bottom-btn-details {
        background: #1F2939;
        color: $white;
        padding: 6px 12px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        // margin-right: 15px;
        font-weight: 500;
        font-size: 14px;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        margin-right: 0;
        @media all and (max-width: 576px) {
            width: 100%;
            margin-top: 10px;
            margin-right: 0;
        }
    }
    &__bottom-chevron{
        color: #000;
        display: flex;
        cursor: pointer;
        span {
            display: flex;
            font-weight: 600;
            align-items: center;
            white-space: nowrap;
        }
        @media (max-width: 576px) {
            display: none;
        }
    }
}
</style>
